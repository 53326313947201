// FIXME: fix this
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable quotes */

import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import routes from './Routes';

export default function PrivateRoute({ children, ...rest }) {
   console.log('PrivateRoute');

   return (
      <Route
         {...rest}
         render={props => {
            const { location, history } = props;
            // Reperimento route attuale
            const { label } =
               routes.find(route => route.path === location.pathname) ?? routes.find(route => route.path === '*');
            console.log('PrivateRoute => ( isAuthenticated, component ) : ', rest.isAuthenticated, label);
            /**
             * Se utente non è di tipo responsabile non può accedere quindi
             *  lo mando alla login dove verrà bloccato
             */
            if (rest.role !== 'manager') {
               console.log('Replacing history due to unauthorized access ...');
               history.replace('/login');
            }
            /*
             * Se sono gia autenticato ed è stata richiesta la pagina
             * di login sostituisco la history e mando l'utente alla home
             */
            if (rest.isAuthenticated && location.pathname === '/login') {
               console.log('Replacing history...');
               history.replace('/');
            }
            // Se utente autenticato procede con il render del componente
            if (rest.isAuthenticated) return children;

            // Se utente non autenticato reindirizzo alla pagine di login
            console.log('PrivateRoute => redirect To ', label);
            const message = label
               ? `Per accedere alla pagina "${label}" devi eseguire l'accesso`
               : `Per continuare devi eseguire l'accesso`;
            return <Redirect to={{ pathname: '/login', state: { from: props.location, message } }} {...rest} />;
         }}
      />
   );
}
