import React from 'react';
import { Badge } from 'react-bootstrap';
import Tooltip from 'react-tooltip-lite';
import BookIcon from '@material-ui/icons/Book';

const bootstrapColor = ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'dark'];

var styles = {
   padding: '0.1em 0 0.15em 0.4em',
   float: 'right',
   color: '#1e2e62',
   fontSize: 16
};

export const generateTags = (key, tagsList) => (
   <div key={`tags-list-${key}`} id="tag">
      <ul>
         {tagsList.filter(t=>t !== '@readonly').map((t,i) => (
            <li key={`${key}-tag-${i}-0`}>
               {t !== '@argomento' ? (
                  <Badge key={`${key}-badge-${i}-1`} variant={bootstrapColor[1]}>{t}</Badge>
               ) : (
                  <Badge style={styles}>
                     <Tooltip content={t} className="target">
                        <BookIcon />
                     </Tooltip>
                  </Badge>
               )}
            </li>
         ))}
      </ul>
   </div>
)
