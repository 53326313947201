import React from 'react';
import { useLocation } from 'react-router-dom';

// import FileViewer from './core/file-viewer/file-viewer';
// import imagea from './core/file-viewer/examples/02-USVI-Solar.jpg';

// 404 Page
export function NoMatch() {
   const location = useLocation();

   return (
      <div>
         <h3>
            No match for <code>{location.pathname}</code>
         </h3>
      </div>
   );
}

export function Home(props) {
   return (
      <div>
         <h1> HOME PAGE </h1>
         <h2>Welcome {props.username}</h2>
         {/* <FileViewer
                fileType="jpg"
                filePath={imagea}
            /> */}
      </div>
   );
}

export function About() {
   return (
      <div>
         <h2>About</h2>
      </div>
   );
}
