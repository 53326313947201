// React
import React from 'react';
import { ToggleButton } from '@material-ui/lab';

export default function Button(props) {
   const { value } = props;
   let label;
   switch (parseInt(value)) {
      case 10:
         label = 'OTTIMO';
         break;
      case 8:
         label = 'BUONO';
         break;
      case 6:
         label = 'SUFFICIENTE';
         break;
      case 4:
         label = 'INSUFFICIENTE';
         break;
      default:
         label = null;
         break;
   }
   return <ToggleButton value={label} children={label} />;
}
