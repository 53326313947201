/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
// TreeMenu
import TreeMenu, { ItemComponent } from 'react-simple-tree-menu';
import {
   Row,
   Col,
   InputGroup,
   Button,
   DropdownButton,
   FormControl,
   Dropdown,
   Form,
   ButtonGroup
} from 'react-bootstrap';
/* import Form from 'react-bootstrap/Form'; */
import { Alert, AlertTitle } from '@material-ui/lab';
// custom
import Table from './Table';
import { generateTags } from '../core/helper';
import SearchIcon from '@material-ui/icons/Search';
//CSV creation and download
import { CSVLink } from 'react-csv';
// Style
import './DashboardDocuments.css';

// https://github.com/iannbing/react-simple-tree-menu/blob/master/stories/index.stories.js
const APP_JSON = 'application/json';
const searchTypeEnum = { FILE_NAME: 'filename', TAG: 'tag' /*, ARGOMENTO: 'argomento'*/ };
export default class Dashboard extends Component {
   constructor(props) {
      super(props);
      this.state = {
         data: null,
         currentElementID: '',
         elementName: '',
         nodeInfo: null,
         isLoading: false,
         searchType: searchTypeEnum.FILE_NAME,
         message: null,
         users: [],
         docsToSearch: [],
         downloadCsv: false
      };
      this.treeSearch = this.treeSearch.bind(this);
      this.onClickItem = this.onClickItem.bind(this);
      this.onSelectSearch = this.onSelectSearch.bind(this);
      this.searchChecked = this.searchChecked.bind(this);
      this.performSearch = this.performSearch.bind(this);
      this.textInput = React.createRef();
   }

   async componentDidMount() {
      this.fetchNodes();
   }
   async fetchNodes() {
      const { server } = this.props;
      const documentRoot = 'shared';
      try {
         const response = await fetch(`${server}/api/alfresco/nodes/node/list`, {
            method: 'POST',
            credentials: 'include',
            headers: { Accept: APP_JSON, 'Content-Type': APP_JSON },
            body: JSON.stringify({ documentRoot, isManApp: true })
         });
         const result = await response.json();
         console.log('LIST API RESULT');
         console.log('', result);
         this.setState({ data: result });
      } catch (error) {
         console.log(error);
      }
   }

   // eslint-disable-next-line class-methods-use-this
   async onClickItem(element) {
      if (!element) return;
      if ((element.isFolder && !element.isTopic) || element.isReadOnly) {
         this.setState({
            users: [],
            isLoading: false,
            message: false,
            docsToSearch: [],
            downloadCsv: false
         });
         return;
      }

      console.log('element.key', element.key);

      const currentElementID = element.key.substring(element.key.lastIndexOf('/') + 1);
      const elementName = element.label;
      const isTopic = element.isTopic;
      // const parentElementID = element.parent.substring(element.parent.lastIndexOf('/') + 1);
      const { server } = this.props;
      let nodeInfo = null;

      // Get Node Info
      try {
         const response = await fetch(`${server}/api/alfresco/nodes/node`, {
            method: 'POST',
            credentials: 'include',
            headers: { Accept: APP_JSON, 'Content-Type': APP_JSON },
            body: JSON.stringify({ elementID: currentElementID })
         });
         nodeInfo = await response.json();
         console.log('Dashboard.onClickItem : Get Node Information ', nodeInfo);
      } catch (error) {
         console.error('Cannot get node info', error);
      }

      // Get Node content and update children if it's a File
      if ((nodeInfo && nodeInfo.isFile) || (nodeInfo.isFolder && isTopic)) {
         console.log('Dashboard.onClickItem : isFile ');
         this.setState({ currentElementID, nodeInfo, elementName });
         try {
            const response = await fetch(`${server}/api/docs/scores`, {
               method: 'POST',
               credentials: 'include',
               headers: { Accept: APP_JSON, 'Content-Type': APP_JSON },
               body: JSON.stringify({ currentElementID })
            });
            const result = await response.json();

            console.log('RESULT -> ', result.length);
            this.setState({
               users: result,
               isLoading: true,
               message: result.length === 0,
               docsToSearch: [],
               downloadCsv: true
            });

            console.log('Fetched document : ', this.state);
         } catch (error) {
            console.log(error);
         }
      }
   }

   performSearch(event, searchFunction) {
      console.log('performSearch : ');
      searchFunction(event.target.value);
   }

   onSelectSearch(eventKey, event, resetOpenNodes) {
      resetOpenNodes();
      this.textInput.current.value = '';
      this.setState({ searchType: searchTypeEnum[eventKey] });
   }

   treeSearch({ searchTerm, label, tags, isFolder, isTopic }) {
      //console.log(`Search by "${this.state.searchType}" , terms : "${searchTerm}" `);
      // Escludo cartelle dalla ricerca
      if (!!isFolder && !isTopic) return false;
      // Ricerco confrontando il toLowerCase()
      /*
         console.group('treeSearch');
         console.log('searchType : ', this.state.searchType);
         console.log('searchTerm : ', searchTerm);
         console.log('label : ', label.toLowerCase().includes(searchTerm.toLowerCase()));
         console.log('tags : ', tags.find(e => e.toLowerCase().includes(searchTerm.toLowerCase())) ? true : false);
         console.groupEnd();
      */
      let result = false;
      switch (this.state.searchType) {
         case searchTypeEnum.FILE_NAME:
            result = label.toLowerCase().includes(searchTerm.toLowerCase());
            break;
         case searchTypeEnum.TAG:
            // Se non ci sono tags evito di cercare inutilmente
            //if (tags.length !== 0) result = !!tags.find(e => e.toLowerCase().includes(searchTerm.toLowerCase()));
            if (tags.length === 0) return false;
            let tagsArrayWoutSysTags = tags.filter(tag => !tag.includes('@'));
            return tagsArrayWoutSysTags.find(e => e.toLowerCase().includes(searchTerm.toLowerCase())) ? true : false;
         default:
            result = label.toLowerCase().includes(searchTerm.toLowerCase());
            break;
      }

      if (result) {
         console.log(`Search by "${this.state.searchType}" , terms "${searchTerm}" in : `, label, tags);
      }
      // console.log("treeSearch.false");
      return result;
   }

   onCheckChange(event, key) {
      const currentElementID = key.substring(key.lastIndexOf('/') + 1);
      /* console.log('event.target', event.target);
      console.log('event.target.id', event.target.id);
      console.log('event.target.checked', event.target.checked); */
      this.setState({
         docsToSearch: event.target.checked
            ? [...this.state.docsToSearch, currentElementID]
            : this.state.docsToSearch.filter(e => e != currentElementID)
      });
   }

   async searchChecked() {
      // console.log(this.state.docsToSearch);

      const keysArray = this.state.docsToSearch;
      const { server } = this.props;
      try {
         const response = await fetch(`${server}/api/docs/multiDocScores`, {
            method: 'POST',
            credentials: 'include',
            headers: { Accept: APP_JSON, 'Content-Type': APP_JSON },
            body: JSON.stringify({ keysArray })
         });
         const result = await response.json();
         //console.log('multiDocScores result', result);

         this.setState({
            users: result,
            isLoading: true,
            message: result.length === 0,
            downloadCsv: result.length !== 0,
            elementName: result.length !== 0 ? 'Documenti selezionati' : ''
         });

         //console.log('Fetched documents : ', this.state);
      } catch (error) {
         console.log(error);
      }
   }
   clearSelected() {
      this.setState({
         users: [],
         isLoading: false,
         message: false,
         docsToSearch: [],
         downloadCsv: false
      });
   }

   render() {
      const { data, isLoading, elementName, message, searchType, downloadCsv } = this.state;

      // Tipi di ricerca disponibili ( ricordarsi di implementare anche la ricerca )
      let searchTypeElement = [];
      for (const key in searchTypeEnum)
         searchTypeElement.push(
            <Dropdown.Item key={`keySearchType-${key}`} eventKey={key}>
               {searchTypeEnum[key]}
            </Dropdown.Item>
         );

      const headers = [
         { label: 'Nome Documento', key: 'docName' },
         { label: 'Utente', key: 'username' },
         { label: 'Score', key: 'score' },
         { label: 'Rating', key: 'rating' }
      ];
      const CSVdata = this.state.users.map(e => ({
         docName: e.pwaDocId,
         username: e.pwaUserId,
         score: e.score,
         rating: e.rating
      }));

      //console.log('selected : ', this.state.docsToSearch);
      //console.log('data : ', data);

      return (
         <>
            <Row>
               <Col md={12}>
                  <div className="kt-section__content kt-aside">
                     <TreeMenu
                        data={data}
                        debounceTime={125}
                        onClickItem={this.onClickItem}
                        matchSearch={this.treeSearch}
                        cacheSearch={false}
                     >
                        {({ search, items, /*searchTerm,*/ resetOpenNodes }) => (
                           <>
                              <InputGroup className="mb-3">
                                 <InputGroup.Prepend>
                                    <Button onClick={resetOpenNodes}>Comprimi</Button>
                                    <DropdownButton
                                       variant="outline-secondary"
                                       title={searchType}
                                       id="searchTypeDropDown"
                                       onSelect={(eventKey, event) =>
                                          this.onSelectSearch(eventKey, event, resetOpenNodes)
                                       }
                                    >
                                       {searchTypeElement}
                                    </DropdownButton>
                                 </InputGroup.Prepend>

                                 <div className="has-search custom-file">
                                    <SearchIcon className="form-control-feedback" />
                                    <FormControl
                                       ref={this.textInput}
                                       onChange={e => this.performSearch(e, search)}
                                       aria-describedby="basic-addon1"
                                       placeholder="Search documents"
                                    />
                                 </div>
                              </InputGroup>
                              <ul>
                                 {items.map(({ key, ...props }, i) => (
                                    <div key={`outer-${key}-${i + 1}`}>
                                       <div
                                          key={`inner-${key}-${i + 1}`}
                                          className={
                                             props.tags.length > 0 ? 'containItem containItem-tag ' : 'containItem '
                                          }
                                       >
                                          <Form key={`form-${key}`}>
                                             <div key={`checkbox-${key}`} className="mb-2">
                                                <Form.Check
                                                   disabled={!(props.isTopic || (!props.isFolder && !props.isReadOnly))}
                                                   type={'checkbox'}
                                                   id={`${props.label}`}
                                                   key={`${key}-item`}
                                                   onChange={event => this.onCheckChange(event, key)}
                                                   checked={this.state.docsToSearch.includes(
                                                      key.substring(key.lastIndexOf('/') + 1)
                                                   )}
                                                />
                                             </div>
                                          </Form>
                                          <ItemComponent {...props} key={`${key}-item-element`} />
                                          {/*<Icon stato={props.stato} key={`${key}-icon`} />*/}
                                       </div>
                                       {props.tags.length > 0 ? generateTags(key, props.tags) : null}
                                    </div>
                                 ))}
                              </ul>
                           </>
                        )}
                     </TreeMenu>
                  </div>

                  <ButtonGroup className="mb-2">
                     <Button onClick={() => this.searchChecked()} variant="primary">
                        Search selected
                     </Button>
                     <Button onClick={() => this.clearSelected()} variant="primary">
                        Clear selection
                     </Button>

                     {downloadCsv === true ? (
                        <CSVLink
                           data={CSVdata}
                           headers={headers}
                           target="_blank"
                           separator={';'}
                           filename={'docummy_scores.csv'}
                        >
                           <Button variant="primary">Download CSV</Button>
                        </CSVLink>
                     ) : (
                        <Button disabled variant="secondary">
                           Download CSV
                        </Button>
                     )}
                  </ButtonGroup>
               </Col>
            </Row>
            {isLoading ? (
               <Row>
                  <Col>
                     <h4 className="page-search-title">
                        Search Results For: <big>{elementName}</big>
                     </h4>
                     <Table fetchedUsers={this.state.users} />
                  </Col>
               </Row>
            ) : null}

            {message === true ? (
               <Row className="justify-content-md-center">
                  <Col md="8">
                     <Alert severity="warning" variant="outlined" className="mt-5">
                        <AlertTitle>La ricerca non ha prodotto nessun risultato</AlertTitle>
                        Il documento selezionato non ha ancora ricevuto nessuna valutazione
                     </Alert>
                  </Col>
               </Row>
            ) : null}
         </>
      );
   }
}
