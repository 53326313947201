// React
import React from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Rating from '@material-ui/lab/Rating';
import EvalButton from '../EvalButton';

export default function Tables(props) {
   const { fetchedUsers } = props;

   return (
      <TableContainer component={Paper} className="mng-search-content">
         <Table aria-label="simple table">
            <colgroup>
               <col style={{ width: '30%' }} />
               <col style={{ width: '15%' }} />
               <col style={{ width: '14%' }} />
               <col style={{ width: '14%' }} />
               <col style={{ width: '37%' }} />
            </colgroup>
            <TableHead>
               <TableRow>
                  <TableCell>Documento</TableCell>
                  <TableCell>Utente</TableCell>
                  <TableCell align="right">Self Evaluation</TableCell>
                  <TableCell align="right">Qualità documento</TableCell>
                  <TableCell align="right">Commenti</TableCell>
               </TableRow>
            </TableHead>
            <TableBody>
               {fetchedUsers.map(row => (
                  <TableRow key={row.documentName}>
                     <TableCell component="th" scope="row">
                        {row.pwaDocId}
                     </TableCell>
                     <TableCell component="th" scope="row">
                        {row.pwaUserId}
                     </TableCell>
                     <TableCell align="right">
                        <EvalButton value={row.score} />
                     </TableCell>
                     <TableCell align="right">
                        <Rating name="rating" value={row.rating} readOnly />
                     </TableCell>
                     <TableCell align="right">{row.comment}</TableCell>
                  </TableRow>
               ))}
            </TableBody>
         </Table>
      </TableContainer>
   );
}
