import React from 'react';

// Components
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';

function Footer() {
   return (
      <Box mt={8}>
         <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://ebsconsultingsrl.it/">
               EBS Consulting
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
         </Typography>
      </Box>
   );
}

export default Footer;
