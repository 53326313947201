/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';

// custom
import Table from './Table';

import { Typeahead } from 'react-bootstrap-typeahead';
import { Row, Col } from 'react-bootstrap';
import { Alert, AlertTitle } from '@material-ui/lab';
// Style
import './DashboardUsers.css';

// https://material-ui.com

// https://github.com/iannbing/react-simple-tree-menu/blob/master/stories/index.stories.js
const APP_JSON = 'application/json';
export default class Dashboard extends Component {
   constructor(props) {
      super(props);
      this.state = {
         data: null,
         users: [],
         docs: [],
         selectedUser: null,
         message: null,
         isLoading: false
      };
      //console.log('Dashboard.constructor : props', this.props);
   }

   async componentDidMount() {
      const { username, server } = this.props;
      try {
         const response = await fetch(`${server}/api/user/list`, {
            method: 'POST',
            credentials: 'include',
            headers: { Accept: APP_JSON, 'Content-Type': APP_JSON },
            body: JSON.stringify({ username })
         });
         const result = await response.json();
         this.setState({ users: result });
      } catch (error) {
         console.log(error);
      }
   }

   async onSelectedValue(value) {
      const { server } = this.props;

      if (value.length === 0) {
         this.setState({ isLoading: false });
      } else if (value.length === 1) {
         try {
            const response = await fetch(`${server}/api/docs/findDoc`, {
               method: 'POST',
               credentials: 'include',
               headers: { Accept: APP_JSON, 'Content-Type': APP_JSON },
               body: JSON.stringify({ selectedUser: value[0].id })
            });
            const result = await response.json();
            if (result.length === 0) {
               console.log('RESULT -> ', result.length);
               this.setState({ message: true, isLoading: false });
            } else {
               this.setState({ docs: result, selectedUser: value[0].name, isLoading: true, message: false });
            }
         } catch (error) {
            console.log(error);
         }
      }
   }

   render() {
      const { users, isLoading, message } = this.state;
      console.log('Dashboard.render : this.state ->', this.state);
      //console.log('MESSAGGIO ->', this.state.message);
      return (
         <>
            <Row>
               <Col>
                  <Typeahead
                     id="typohead"
                     className="form-control-typeahead"
                     labelKey="name"
                     filterBy={['name']}
                     options={users}
                     placeholder="Choose a user..."
                     onChange={selectedValue => this.onSelectedValue(selectedValue)}
                  />
               </Col>
            </Row>
            {isLoading ? (
               <Row>
                  <Col>
                     <h4 className="page-search-title">Search Results</h4>
                     <Table selectedDocs={this.state.docs} />
                  </Col>
               </Row>
            ) : null}

            {message === true ? (
               <Row className="justify-content-md-center">
                  <Col md="8">
                     <Alert severity="warning" variant="outlined" className="mt-5">
                        <AlertTitle>La riserca non ha prodotto nessun risultato</AlertTitle>
                        L'utente selezionato non ha valutato nessun documento
                     </Alert>
                  </Col>
               </Row>
            ) : null}
         </>
      );
   }
}
