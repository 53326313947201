// React
import React, { Component } from 'react';
import { Alert, Form, Row, Col } from 'react-bootstrap';
// Router
import { withRouter } from 'react-router';
import Cookies from 'universal-cookie';
// Style
import './Login.css';

import { Container, TextField, Typography, Button, Paper } from '@material-ui/core';
import { Alert as AlertMaterial, AlertTitle } from '@material-ui/lab';

import IconaManager from '../../assets/icon-manager.svg';

class LoginClass extends Component {
   constructor(props) {
      super(props);
      this.state = { username: '', password: '', message: '' };
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
   }

   handleChange(event) {
      this.setState({ [event.currentTarget.name]: event.currentTarget.value });
   }

   // chiamare API autenticazione
   async handleSubmit(event) {
      event.preventDefault();
      // Test Auth
      const { username, password } = this.state;
      const { history, location, server } = this.props;
      let message = '';
      if (username === '' || password === '') {
         message = 'Utente o Password mancanti.';
         this.setState({ message });
         return;
      }

      try {
         const isAmmApp = true;
         const response = await fetch(`${server}/api/alfresco/auth`, {
            method: 'POST',
            credentials: 'include',
            headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
            body: JSON.stringify({ username, password, isAmmApp })
         });
         const result = await response.json();
         if (response.ok) {
            const { handleLogin } = this.props;
            var expires = new Date();
            const exdays = 1; // numero di giorni prima della scadenza
            expires.setTime(expires.getTime() + exdays * 24 * 60 * 60 * 1000);
            const cookies = new Cookies();
            if (process.env.NODE_ENV === 'production')
               cookies.set('sessionID', result.sessionID, {
                  path: '/',
                  domain: '.ebsconsultingsrl.it',
                  sameSite: 'none',
                  expires,
                  secure: true
               });
            else
               cookies.set('sessionID', result.sessionID, {
                  path: '/',
                  expires
               });
            handleLogin(result.username, result.personNameSurname, result.role, result.sessionID);
            // https://reacttraining.com/react-router/web/example/auth-workflow
            const { from } = location.state || { from: { pathname: '/' } };
            history.replace(from);
         } else {
            switch (response.status) {
               case 400:
                  message = 'Utente o Password mancanti. Inserire i dati e ripetere il login. ';
                  break;
               case 403:
                  message = 'Utente o Password errati! ';
                  break;
               case 401:
                  message = 'Utente non autorizzato! ';
                  break;
               default:
                  message = 'Errore interno. Login momentaneamente non disponibile, riprovare piu tardi.';
                  break;
            }
         }
      } catch (err) {
         message = 'Errore interno. Login momentaneamente non disponibile, riprovare piu tardi.';
      }
      this.setState({ message });
   }

   // Render form username/password
   render() {
      const { location, role } = this.props;
      const { message } = this.state;
      const { pathname: from } = location.state?.from ?? '/';
      const redirectMessage = from !== '/' && from !== '/login' ? location?.state?.message : '';

      let loginEnable = false;
      if (role === '' || role === 'manager') loginEnable = true;
      return (
         <>
            {/* Form Login */}
            <Container component="main">
               <Row className="justify-content-md-center">
                  <Col xs={12} md={8} lg={7}>
                     <Paper className="ebs-card">
                        {// Login non autorizza se utente non è di tipo amministratore
                        !loginEnable ? (
                           <AlertMaterial severity="error">
                              <AlertTitle>Attenzione</AlertTitle>
                              Non sei autorizzato ad accedere a questo portale
                           </AlertMaterial>
                        ) : null}

                        {// Credenziali errate o login non disponibile
                        message ? (
                           <Alert key={0} variant="danger">
                              {message}
                           </Alert>
                        ) : null}

                        {// Messaggio di richiesta login se utente ha richiesto una pagina interna ma non è loggato
                        redirectMessage && redirectMessage !== '' ? (
                           <Alert key={1} variant="warning">
                              {redirectMessage}
                           </Alert>
                        ) : null}

                        <img src={IconaManager} className="icon-elements" alt="" role="presentation" />
                        <Typography component="h1" variant="h4">
                           Login. <small>Benvenuto</small>
                        </Typography>
                        <p>Inserisci il tuo nome utente e password per continuare </p>
                        <Form onSubmit={this.handleSubmit} id="login">
                           <TextField
                              variant="outlined"
                              margin="normal"
                              required
                              fullWidth
                              id="username"
                              label="Username"
                              name="username"
                              onChange={this.handleChange}
                              disabled={loginEnable === true ? false : true}
                              autoFocus
                           />
                           <TextField
                              variant="outlined"
                              margin="normal"
                              required
                              fullWidth
                              name="password"
                              label="Password"
                              type="password"
                              id="password"
                              onChange={this.handleChange}
                              disabled={loginEnable === true ? false : true}
                           />
                           <Button
                              type="submit"
                              fullWidth
                              variant="contained"
                              color="secondary"
                              className="submit"
                              disabled={loginEnable === true ? false : true}
                           >
                              Login
                           </Button>
                        </Form>
                     </Paper>
                  </Col>
               </Row>
            </Container>
         </>
      );
   }
}

const Login = withRouter(LoginClass);
export default Login;
