// React
import React from 'react';
// Style
import './Dropdown.css';

// Components
import { Dropdown } from 'react-bootstrap';
import Avatar from '@material-ui/core/Avatar';

export default function Dropdowns(props) {
   const server = process.env.REACT_APP_SERVER_URL;

   const { username, handleLogouts } = props;

   return (
      <>
         <Dropdown>
            <Dropdown.Toggle
               variant="default"
               alignright="true"
               drop="right"
               size="sm"
               id="dropdown-basic"
               alt={username.toUpperCase()}
               src="/static/media/avatar/1.jpg"
            >
               <Avatar alt={username.toUpperCase()} src="/static/media/avatar/1.jpg" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
               <Dropdown.Item disabled>{username}</Dropdown.Item>
               <Dropdown.Divider />

               {server.includes('stage') === true || server.includes('localhost') === true ? (
                  <Dropdown.Item href="https://stage-share.ebsconsultingsrl.it/share/" target="_blank">
                     Interfaccia Editors
                  </Dropdown.Item>
               ) : (
                  <Dropdown.Item href="https://share.ebsconsultingsrl.it/share/" target="_blank">
                     Interfaccia Editors
                  </Dropdown.Item>
               )}

               {server.includes('localhost') === true ? (
                  <Dropdown.Item href="localhost:3000" target="_blank">
                     Interfaccia Users
                  </Dropdown.Item>
               ) : server.includes('stage') === true ? (
                  <Dropdown.Item href="https://stage-mydoc.ebsconsultingsrl.it/" target="_blank">
                     Interfaccia Users
                  </Dropdown.Item>
               ) : (
                  <Dropdown.Item href="https://mydoc.ebsconsultingsrl.it/" target="_blank">
                     Interfaccia Users
                  </Dropdown.Item>
               )}

               <Dropdown.Divider />
               <Dropdown.Item onClick={handleLogouts}>Logout</Dropdown.Item>
            </Dropdown.Menu>
         </Dropdown>
      </>
   );
}
